import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-bridgeport-connecticut.png'
import image0 from "../../images/cities/scale-model-of-housatonic-museum-of-art-in-bridgeport-connecticut.png"
import image1 from "../../images/cities/scale-model-of-seaside-park-in-bridgeport-connecticut.png"
import image2 from "../../images/cities/scale-model-of-connecticut-audubon-society-in-bridgeport-connecticut.png"
import image3 from "../../images/cities/scale-model-of-boothe-memorial-park-in-bridgeport-connecticut.png"
import image4 from "../../images/cities/scale-model-of-barnum-museum,-the-in-bridgeport-connecticut.png"
import image5 from "../../images/cities/scale-model-of-fairfield-museum-and-history-center-in-bridgeport-connecticut.png"
import image6 from "../../images/cities/scale-model-of-national-helicopter-museum-inc-in-bridgeport-connecticut.png"
import image7 from "../../images/cities/scale-model-of-riviera-tours-in-bridgeport-connecticut.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Bridgeport'
            state='Connecticut'
            image={image}
            lat='41.1874'
            lon='-73.1958'
            attractions={ [{"name": "Housatonic Museum of Art", "vicinity": "900 Lafayette Blvd, Bridgeport", "types": ["art_gallery", "museum", "point_of_interest", "establishment"], "lat": 41.1744542, "lng": -73.19128810000001}, {"name": "Seaside Park", "vicinity": "1 Barnum Dyke, Bridgeport", "types": ["park", "point_of_interest", "establishment"], "lat": 41.16159710000001, "lng": -73.18810689999998}, {"name": "Connecticut Audubon Society", "vicinity": "2325 Burr St, Fairfield", "types": ["park", "point_of_interest", "establishment"], "lat": 41.2022948, "lng": -73.29346720000001}, {"name": "Boothe Memorial Park", "vicinity": "5800 Main St, Stratford", "types": ["museum", "park", "point_of_interest", "establishment"], "lat": 41.2352088, "lng": -73.1094592}, {"name": "Barnum Museum, the", "vicinity": "820 Main St, Bridgeport", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.1756635, "lng": -73.18815519999998}, {"name": "Fairfield Museum and History Center", "vicinity": "370 Beach Rd, Fairfield", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 41.1413159, "lng": -73.24883219999998}, {"name": "National Helicopter Museum Inc", "vicinity": "2480 Main St, Stratford", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.1947255, "lng": -73.13048850000001}, {"name": "Riviera Tours", "vicinity": "501 Kings Hwy E # 200, Fairfield", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.1720311, "lng": -73.22803320000003}] }
            attractionImages={ {"Housatonic Museum of Art":image0,"Seaside Park":image1,"Connecticut Audubon Society":image2,"Boothe Memorial Park":image3,"Barnum Museum, the":image4,"Fairfield Museum and History Center":image5,"National Helicopter Museum Inc":image6,"Riviera Tours":image7,} }
       />);
  }
}